@use 'sass:map';
@use '@angular/material' as mat;
@use './m3-palettes' as userThemes;

/* Include the core Angular Material styles */
@include mat.core();

@import '@ng-matero/extensions/prebuilt-themes/azure-blue.css';

/* Create a base theme without any color to set the density and typography */
$base-theme: mat.define-theme((
  color: null,
  density: (
    scale: 0,
  ),
  typography: (
    use-system-variables: true,
  ),
));
html {
  @include mat.all-component-themes($base-theme);
  @include mat.system-level-typography($base-theme);
}

/* Loop trough user themes and generate Angular Material themes */
@each $name, $theme in userThemes.$palettes {

  $rest: (
    secondary: map.get($theme, secondary),
    neutral: map.get($theme, neutral),
    neutral-variant: map.get($theme, neutral-variant),
    error: map.get($theme, error),
  );

  $primary: map.merge(map.get($theme, primary), $rest);
  $tertiary: map.merge(map.get($theme, tertiary), $rest);


  /* Light theme */
  $light-theme: mat.define-theme((
    color: (
      theme-type: light,
      primary: $primary,
      tertiary: $tertiary,
    ),
  ));

  /* Dark theme */
  $dark-theme: mat.define-theme((
    color: (
      theme-type: dark,
      primary: $primary,
      tertiary: $tertiary,
    ),
  ));

  #{map.get($theme, selector)} .light,
  #{map.get($theme, selector)}.light {
    @include mat.all-component-colors($light-theme);
  }

  #{map.get($theme, selector)} .dark,
  #{map.get($theme, selector)}.dark {
    @include mat.all-component-colors($dark-theme);
  }
}

:root {
  --sys-display-large: 800 1.875rem / 2.25rem theme('fontFamily.sans');
  --sys-display-large-font: theme('fontFamily.sans');
  --sys-display-large-line-height: 2.25rem;
  --sys-display-large-size: 1.875rem;
  --sys-display-large-tracking: 0rem;
  --sys-display-large-weight: 800;

  --sys-display-medium: 700 1.25rem / 1.75rem theme('fontFamily.sans');
  --sys-display-medium-font: theme('fontFamily.sans');
  --sys-display-medium-line-height: 1.75rem;
  --sys-display-medium-size: 1.25rem;
  --sys-display-medium-tracking: 0rem;
  --sys-display-medium-weight: 700;

  --sys-display-small: 600 1.125rem / 1.75rem theme('fontFamily.sans');
  --sys-display-small-font: theme('fontFamily.sans');
  --sys-display-small-line-height: 1.75rem;
  --sys-display-small-size: 1.125rem;
  --sys-display-small-tracking: 0rem;
  --sys-display-small-weight: 600;

  --sys-headline-large: 600 0.875rem / 1.25rem theme('fontFamily.sans');
  --sys-headline-large-font: theme('fontFamily.sans');
  --sys-headline-large-line-height: 1.25rem;
  --sys-headline-large-size: 0.875rem;
  --sys-headline-large-tracking: 0rem;
  --sys-headline-large-weight: 600;

  --sys-headline-medium: 400 0.875rem / 1.5rem theme('fontFamily.sans');
  --sys-headline-medium-font: theme('fontFamily.sans');
  --sys-headline-medium-line-height: 1.5rem;
  --sys-headline-medium-size: 0.875rem;
  --sys-headline-medium-tracking: 0rem;
  --sys-headline-medium-weight: 400;

  --sys-headline-small: 400 0.875rem / 1.5rem theme('fontFamily.sans');
  --sys-headline-small-font: theme('fontFamily.sans');
  --sys-headline-small-line-height: 1.5rem;
  --sys-headline-small-size: 0.875rem;
  --sys-headline-small-tracking: 0rem;
  --sys-headline-small-weight: 400;

  --sys-title-large: 400 1rem / 1.75rem theme('fontFamily.sans');
  --sys-title-large-font: theme('fontFamily.sans');
  --sys-title-large-line-height: 1.75rem;
  --sys-title-large-size: 1rem;
  --sys-title-large-tracking: 0rem;
  --sys-title-large-weight: 400;

  --sys-title-medium: 600 0.875rem / 1.25rem theme('fontFamily.sans');
  --sys-title-medium-font: theme('fontFamily.sans');
  --sys-title-medium-line-height: 1.25rem;
  --sys-title-medium-size: 0.875rem;
  --sys-title-medium-tracking: 0rem;
  --sys-title-medium-weight: 600;

  --sys-body-large: 400 0.875rem / 1.5rem theme('fontFamily.sans');
  --sys-body-large-font: theme('fontFamily.sans');
  --sys-body-large-line-height: 1.5rem;
  --sys-body-large-size: 0.875rem;
  --sys-body-large-tracking: 0rem;
  --sys-body-large-weight: 400;

  --sys-body-medium: 400 0.875rem / 1.5rem theme('fontFamily.sans');
  --sys-body-medium-font: theme('fontFamily.sans');
  --sys-body-medium-line-height: 1.5rem;
  --sys-body-medium-size: 0.875rem;
  --sys-body-medium-tracking: 0rem;
  --sys-body-medium-weight: 400;

  --sys-body-small: 400 0.75rem / 1rem theme('fontFamily.sans');
  --sys-body-small-font: theme('fontFamily.sans');
  --sys-body-small-line-height: 1rem;
  --sys-body-small-size: 0.75rem;
  --sys-body-small-tracking: 0rem;
  --sys-body-small-weight: 400;

  --sys-label-large: 500 0.875rem / 0.875rem theme('fontFamily.sans');
  --sys-label-large-font: theme('fontFamily.sans');
  --sys-label-large-line-height: 0.875rem;
  --sys-label-large-size: 0.875rem;
  --sys-label-large-tracking: 0rem;
  --sys-label-large-weight: 500;

  --sys-label-medium: 600 0.875rem / 1.25rem theme('fontFamily.sans');
  --sys-label-medium-font: theme('fontFamily.sans');
  --sys-label-medium-line-height: 1.25rem;
  --sys-label-medium-size: 0.875rem;
  --sys-label-medium-tracking: 0rem;
  --sys-label-medium-weight: 600;

  --sys-caption: 400 0.75rem / 1rem theme('fontFamily.sans');
  --sys-caption-font: theme('fontFamily.sans');
  --sys-caption-line-height: 1rem;
  --sys-caption-size: 0.75rem;
  --sys-caption-tracking: 0rem;
  --sys-caption-weight: 400;

  --sys-button: 500 0.875rem / 0.875rem theme('fontFamily.sans');
  --sys-button-font: theme('fontFamily.sans');
  --sys-button-line-height: 0.875rem;
  --sys-button-size: 0.875rem;
  --sys-button-tracking: 0rem;
  --sys-button-weight: 500;

  --sys-overline: 500 0.75rem / 2rem theme('fontFamily.sans');
  --sys-overline-font: theme('fontFamily.sans');
  --sys-overline-line-height: 2rem;
  --sys-overline-size: 0.75rem;
  --sys-overline-tracking: 0rem;
  --sys-overline-weight: 500;
}
