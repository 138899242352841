body splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

body splash-screen svg {
  width: 250px;
  height: 250px;
}

.splash-screen-fade-out {
  opacity: 0;
}

.splash-screen-hidden {
  display: none;
}

body splash-screen svg .stroke {
  fill: none;
  stroke: #000000;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ttg-draw 1s ease-in-out infinite;
}

@keyframes ttg-draw {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
